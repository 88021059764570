
















import Vue from 'vue';
import { date } from 'quasar';

export default Vue.extend({
  name: 'Text-Message-Component',
  props: {
    text: {
      type: Object,
      default: () => {
        return {
          message: '',
          meta: {
            createdOn: new Date().toISOString(),
            sentBy: '',
          },
          recieved: false,
        };
      },
      required: true,
    },
  },
  computed: {
    prettyTime () {
      // Determine time of timestamp
      const prettyString: string = date.formatDate(this.text.meta.createdOn, 'HH:mm MMMM Do YYYY');
      return prettyString;
    },
  },
});
