

















import Vue from 'vue';
import { Tenant as ITenant } from '@lordly/models2/interfaces/models/Tenancy';

export default Vue.extend({
  name: 'Tenant-Chip-Component',
  props: {
    tenant: {
      type: Object as () => Partial<ITenant>,
      default: () => {
        return {
          id: '',
          firstname: 'Click/Tap me',
          surname: 'to add a new tenant',
          contact: {
            mobile: '',
            email: '',
          },
          color: 'black',
          meta: {
            obsolete: false,
          },
        };
      },
      required: true,
    },
    idx: {
      type: Number,
      default: -1,
      required: true,
    },
    removeable: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    isLightColor () {
      if (this.tenant.color === 'yellow' || this.tenant.color === 'aqua') {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    removeChip () {
      this.$emit('remove', this.idx);
    },
  },
});
