

















import Vue from 'vue';

import { Tenant } from '@lordly/models2/interfaces/models/Tenancy';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';

export default Vue.extend({
  name: 'Tenant-Card-Component',
  props: {
    tenant: {
      type: Object as () => Partial<Tenant>,
      default: () => {
        return {
          id: '',
          firstname: 'Click/Tap me',
          surname: 'to add a new tenant',
          contact: {
            mobile: '',
            email: '',
          },
          color: 'black',
          meta: {
            obsolete: false,
          },
        };
      },
      required: true,
    },
    idx: {
      type: Number,
      default: -1,
      required: true,
    },
  },
  computed: {
    fullName (): string {
      return this.tenant.firstname! + ' ' + this.tenant.surname;
    },
  },
});
