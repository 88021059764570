







































import Vue from 'vue';

export default Vue.extend({
  name: 'Tenancy-TODO-Item-Component',
  props: {
    task: {
      type: Object,
      default: () => {
        return {
          label: '',
          value: false,
        };
      },
      required: true,
    },
    index: {
      type: Number,
      default: -1,
      required: true,
    },
  },
  methods: {
    toggleComplete () {
      this.task.value = !this.task.value;
      // If complete, set edit to false
      this.touchedTodo();
    },
    touchedTodo () {
      this.$emit('update');
    },
    removeTodo () {
      this.$emit('remove', this.index);
    },
  },
});
