
















































import Vue from 'vue';
import TenantSelector from '@/blueprint/components/landlord/tenancy/Tenant-Selector.vue';
import { Tenancy } from '@/blueprint/pages/landlord/Tenancy.vue';
import CloseBtn from '@/blueprint/components/ui/CloseButton.vue';

import { Tenancy as ITenancy, Tenant as ITenant } from '@lordly/models2/interfaces/models/Tenancy';
import { API } from '@lordly/models2/interfaces/gql';
import { TextMessage } from '@lordly/models2/interfaces/models/Message';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { ValidateFields } from '@/assets/mixins';

export default Vue.extend({
  name: 'SMS-SendText-View',
  components: {
    'tenant-selector': TenantSelector,
    'close-btn': CloseBtn,
  },
  props: {
    tenancy: {
      type: Object as () => Tenancy,
      required: true,
      default: null,
    },
    tenancyId: {
      type: String,
      default: '',
      required: true,
    },
    tenancyPartition: {
      type: String,
      default: '',
      required: true,
    },
  },
  data: () => {
    return {
      recipients: [] as ITenant[],
      message: '',
      sending: false,
    };
  },
  computed: {
    tenants (): Array<Partial<ITenant>> {
      return this.tenancy.tenants;
    },
  },
  created () {
    this.recipients = this.tenants as ITenant[];
  },
  methods: {
    closeForm () {
      this.$emit('close');
    },
    async sendTextMessage () {
      // Check if form is valid
      const hasError: boolean = !ValidateFields(this, ['message']);
      // If valid
      if (!hasError && (this.tenants && this.tenants.length > 0)) {
        this.sending = true;
        // Create query
        const query: GQLTagRequestObject = gql`
          mutation ($id: String!, $partition: String!, $recipients: [String]!, $message: String!) {
              TenancySendSMSMessage(
                input: {
                  id: $id,
                  partition: $partition,
                  recipients: $recipients,
                  message: $message
                }
              ) {
                message
                received
                meta {
                  createdOn
                }
              }
            }
        `;
        // Get recipeient Ids
        const recipeientIds: string [] = this.recipients.map((t) => {
          return t.id;
        });
        // Create mutation
        const payload: API.TenancySendSMSMessageInput = {
          id: this.tenancyId,
          partition: this.tenancyPartition,
          recipients: recipeientIds,
          message: this.message,
        };
        // Send request
        try {
          const response: Partial<TextMessage> = await this.$gql.Mutation('TenancySendSMSMessage', query, payload);
          // Send history new message
          this.$emit('new', response);
          // Close form
          this.closeForm();
        } catch (e) {
          console.error(e);
        } finally {
          this.sending = false;
      }
      }
    },
    updateRecipients (values: ITenant[]) {
      this.recipients = values;
    },
  },
});
