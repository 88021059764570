





































































































































































import Vue from 'vue';
import TenantCard from '@/blueprint/components/landlord/tenancy/Tenant-Card.vue';
import CloseBtn from '@/blueprint/components/ui/CloseButton.vue';

import { VueInputComponent } from '@/interfaces';
import { Tenancy } from '@/blueprint/pages/landlord/Tenancy.vue';

import { debounce } from 'quasar';
import { ValidateFields, Captialize, ResetForm } from '@/assets/mixins';
import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { API } from '@lordly/models2/interfaces/gql';
import { Tenant } from '@lordly/models2/interfaces/models/Tenancy';

export default Vue.extend({
  name: 'Tenancy-Tenant-View',
  components: {
    'tenant-card': TenantCard,
    'close-btn': CloseBtn,
  },
  inject: ['tenancy'],
  props: {
    tenancyId: {
      type: String,
      default: '',
      required: true,
    },
    tenancyPartition: {
      type: String,
      default: '',
      required: true,
    },
  },
  data: () => {
    return {
      form: {
        id: '',
        color: '',
        firstname: '',
        surname: '',
        mobile: '',
        email: '',
      },
      animateForm: false,
      showForm: false,
      debounceUpsert: () => { return; },
      confirmDeleteTenant: false,
      updateTenantLoading: false,
      tenantColors: ['red', 'aqua', 'green', 'purple', 'yellow', 'orange', 'pink', 'black', 'brown', 'grey'] as string[],
    };
  },
  computed: {
    disableSubmitButton (): boolean {
      if (this.form.id === '') {
        return this.tenantLimitReached;
      } else {
        return this.updateTenantLoading;
      }
    },
    showFormOverlay (): boolean {
      if (this.form.id === '' && this.tenantLimitReached) {
        return true;
      }
      return false;
    },
    tenantLimitReached (): boolean {
      const tenancy: Tenancy = ((this as any).tenancy as Tenancy);
      return tenancy.tenants.length >= tenancy.tenantLimit;
    },
    formTitle () {
      let title: string = 'Add a New Tenant';
      if (this.form.id) {
        title = `Update ${this.form.firstname}'s Tenant Details`;
      } else {
        if (this.tenantLimitReached) {
          title = 'Maximum Tenant Limit Reached';
        }
      }
      return title;
    },
    tenantListLength (): number {
      return ((this as any).tenancy as Tenancy).tenants.length;
    },
  },
  watch: {
    tenantListLength: {
      handler () {
        this.updateAvailableTenantColors();
      },
      immediate: true,
    },
  },
  created () {
    // Initialise
    this.debounceUpsert = debounce(() => {
      if (this.form.id) {
        this.upsertTenant();
      }
    }, 500);
  },
  mounted () {
    // Update tenant colours
    this.updateAvailableTenantColors();
  },
  methods: {
    invokeTenantForm () {
      if (this.form.id === '' && window.innerWidth >= 1022) {
        // Animate only in desktop viewport if form id is empty
        this.animateForm = true;
        setTimeout(() => {
          this.animateForm = false;
        }, 1000);
      }
      // Reset the form
      this.resetTenantForm();
      // Show form
      this.showForm = true;
    },
    resetTenantForm () {
      // Reset form
      this.form = {
        id: '',
        color: '',
        firstname: '',
        surname: '',
        mobile: '',
        email: '',
      };
      // Reset form field validation
      const keys: string[] = Object.keys(this.form);
      ResetForm(this, keys);
    },
    async upsertTenant (obsolete: boolean = false) {
      // Determine if form is valid
      let hasError: boolean = !this.validateFields(['firstname', 'surname', 'mobile', 'email']);
      // If valid
      if (!hasError) {
        // Create query
        const query: GQLTagRequestObject = gql`
          mutation ($id: String!, $partition: String!, $tenant: TenantInput!) {
            UpsertTenant (
              input: {
                id: $id,
                partition: $partition,
                tenant: $tenant
              }
            ) {
              id
              color
              firstname
              surname
              contact {
                mobile
                email
              }
              meta {
                obsolete
              }
            }
          }
        `;
        // Create payload
        const tenancy: Tenancy = (this as any).tenancy as Tenancy;
        const payload: API.UpsertTenantInput = {
          id: this.tenancyId,
          partition: this.tenancyPartition,
          tenant: {
            id: this.form.id,
            color: this.form.color,
            firstname: Captialize(this.form.firstname)!,
            surname: Captialize(this.form.surname)!,
            email: this.form.email,
            mobile: ('+447' + this.form.mobile),
            obsolete,
          },
        };
        // If inserting pick tenant color
        if (!payload.tenant.id) {
          payload.tenant.color = this.pickRandomTenantColor();
        }
        // Send request
        try {
          this.updateTenantLoading = true;
          const tenant: Partial<Tenant> = await this.$gql.Mutation('UpsertTenant', query, payload);
          // Upsert new tenant to tenant list
          const exisitingTenantList: Array<Partial<Tenant>> = ((this as any).tenancy as Tenancy).tenants;
          let insert: boolean = true;
          for (const idx in exisitingTenantList) {
            if (exisitingTenantList[idx]) {
              const existingTenant: Partial<Tenant> = exisitingTenantList[idx];
              if (existingTenant.id === tenant.id) {
                // If tenant was obsoleted, remove from list
                if (obsolete) {
                  console.warn('Remove tenant', tenant, idx);
                  exisitingTenantList.splice(parseInt(idx), 1);
                  this.resetTenantForm();
                } else {
                  // Otherwise updated, replace
                  exisitingTenantList[idx] = tenant;
                }
                insert = false;
              }
            }
          }
          // If tenant not found insert
          if (insert) {
            // Update tenant list
            exisitingTenantList.push(tenant);
            ((this as any).tenancy as Tenancy).tenants = exisitingTenantList;
            // On next tick
            this.$nextTick(() => {
              // Update available colors
              this.updateAvailableTenantColors();
              // Reset form
              this.resetTenantForm();
              // Scroll user to the bottom of tenant list
              const domTenantList: Element = this.$refs.tenantList as Element;
              domTenantList.scrollTop = domTenantList.scrollHeight;
              // Close form on insert
              this.showForm = false;
            });
          }
          // If obsoleted close form
          if (obsolete && this.showForm) {
            this.showForm = false;
          }
        } catch (e) {
          console.error(e);
        } finally {
          this.updateTenantLoading = false;
        }
      }
    },
    validateFields (inputs: string[]) {
      return ValidateFields(this, inputs);
    },
    updateTenant (idx: number) {
      // Try get tenant
      const targetTenant: Partial<Tenant> = ((this as any).tenancy as Tenancy).tenants[idx];
      // Get we find tenant
      if (targetTenant) {
        // Reset the form
        this.resetTenantForm();
        // Sync form
        this.form = {
          id: targetTenant.id!,
          color: targetTenant.color!,
          firstname: targetTenant.firstname!,
          surname: targetTenant.surname!,
          email: targetTenant.contact!.email!,
          mobile: targetTenant.contact!.mobile!.replace(new RegExp('(\\+447)', 'g'), ''),
        };
        // Show form
        this.showForm = true;
      }
    },
    pickRandomTenantColor () {
      const min: number = 0;
      const max: number = this.tenantColors.length;
      const idx: number = Math.floor(Math.random() * ((max - min) + min));
      return this.tenantColors[idx];
    },
    updateAvailableTenantColors () {
      // Update available tenant colors
      const tenants: Array<Partial<Tenant>> = ((this as any).tenancy as Tenancy).tenants;
      for (const idx in tenants) {
        if (tenants[idx]) {
          // Get Tenant
          const tenant: Partial<Tenant> = tenants[idx];
          // Check if color is in array
          const colorIndex: number = this.tenantColors.indexOf(tenant.color!);
          if (colorIndex > -1) {
            // Remove color from array
            this.tenantColors.splice(colorIndex, 1);
          }
        }
      }
    },
    obsoleteTenant () {
      if (this.confirmDeleteTenant) {
        this.upsertTenant(true);
      } else {
        this.confirmDeleteTenant = true;
        setTimeout(() => {
          this.confirmDeleteTenant = false;
        }, 2500);
      }
    },
  },
});
