






































import Vue from 'vue';
import TODO from '@/blueprint/components/landlord/tenancy/TODO-Item.vue';

import { debounce } from 'quasar';

import { TODOItem, Tenancy } from '@/blueprint/pages/landlord/Tenancy.vue';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { API } from '@lordly/models2/interfaces/gql';

export default Vue.extend({
  name: 'Tenancy-TODO-View',
  components: {
    'todo-item': TODO,
  },
  inject: ['tenancy'],
  props: {
    tenancyId: {
      type: String,
      default: '',
      required: true,
    },
    tenancyPartition: {
      type: String,
      default: '',
      required: true,
    },
  },
  data: () => {
    return {
      debounceUpsert: () => { return; },
      loading: false,
    };
  },
  created () {
    this.debounceUpsert = debounce(() => {
      this.upsertTodo();
    }, 1000);
  },
  methods: {
    addTodo () {
      const newTodo: TODOItem = {
        label: '',
        value: false,
      };
      const tenancy: Tenancy = (this as any).tenancy as Tenancy;
      tenancy.todos.push(newTodo);
      this.debounceUpsert();
    },
    removeTodo (idx: number) {
      ((this as any).tenancy as Tenancy).todos.splice(idx, 1);
      this.debounceUpsert();
    },
    async upsertTodo () {
      // Set loading to true
      this.loading = true;
      // Create query
      const query: GQLTagRequestObject = gql`
        mutation ($id: String!, $partition: String!, $todos: [IKeyValuePairBoolean]!) {
          UpsertTODO (input: {
            id: $id,
            partition: $partition,
            todos: $todos
          }) {
            computed {
              complete
            }
          }
        }
      `;
      // Create payload
      const payload: API.UpsertTODOInput = {
        id: this.tenancyId,
        partition: this.tenancyPartition,
        todos: ((this as any).tenancy as Tenancy).todos,
      };
      // Send query
      try {
        await this.$gql.Mutation('UpsertTODO', query, payload);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
});
