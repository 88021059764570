









































































import Vue from 'vue';
import CloseBtn from '@/blueprint/components/ui/CloseButton.vue';
import SMSMessage from '@/blueprint/components/landlord/tenancy/SMS-Message.vue';
import Loading from '@/blueprint/components/ui/Loading.vue';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { API } from '@lordly/models2/interfaces/gql';

import { Tenancy } from '@/blueprint/pages/landlord/Tenancy.vue';
import { TextMessage } from '@lordly/models2/interfaces/models/Message';
import { Tenant } from '@lordly/models2/interfaces/models/Tenancy';
import { QSelectOptions } from '@/interfaces/quasar';

export default Vue.extend({
  name: 'SMS-History-View',
  components: {
    'close-btn': CloseBtn,
    'sms-message': SMSMessage,
    Loading,
  },
  props: {
    tenancy: {
      type: Object as () => Tenancy,
      required: true,
      default: null,
    },
    tenantIdx: {
      type: Number,
      required: true,
      default: -1,
    },
    tenancyId: {
      type: String,
      default: '',
      required: true,
    },
    tenancyPartition: {
      type: String,
      default: '',
      required: true,
    },
  },
  data: () => {
    return {
      historyLoading: false,
      history: [],
    };
  },
  computed: {
    targetTenantName: {
      get (): string {
        if (this.targetTenant) {
          return this.targetTenant.firstname + ' ' + this.targetTenant.surname;
        }
        return '';
      },
      set (value: number): void {
        this.$emit('select', value);
      },
    },
    targetTenantColor (): string {
      if (this.targetTenant) {
        return this.targetTenant.color!;
      }
      return 'white';
    },
    targetTenant (): Partial<Tenant> | null {
      const tenant: Partial<Tenant> = this.tenancy.tenants[this.tenantIdx];
      if (tenant) {
        return tenant;
      }
      return null;
    },
    tenantNames (): QSelectOptions [] {
      // Extract tenancy
      const tenancy: Tenancy = this.tenancy;
      const tenants: Array<Partial<Tenant>> = tenancy.tenants;
      const payload: QSelectOptions[] = [];
      for (const idx in tenants) {
        if (tenants[idx]) {
          const tenant: Partial<Tenant> = tenants[idx];
          const fullname: string = tenant.firstname + ' ' + tenant.surname;
          const optionObject: QSelectOptions = {
            label: fullname,
            value: parseInt(idx),
            description: tenant.color,
          };
          payload.push(optionObject);
        }
      }
      // Set to first user if value is not set
      if (this.tenantIdx === -1) {
        this.targetTenantName = 0;
      }
      return payload;
    },
  },
  watch: {
    tenantIdx: {
      handler () {
        this.getTenantSMSHistory();
      },
      immediate: true,
    },
  },
  // Lifecycle Hooks
  // beforeCreate () {},
  created () {
    // Get tenant history on created
    this.getTenantSMSHistory();
  },
  // beforeMount () {},
  // mounted () {},
  // beforeUpdate () {},
  // updated () {},
  // beforeDestroy () {},
  // destroyed () {},
  // Methods
  methods: {
    async getTenantSMSHistory () {
      if (this.targetTenant) {
        // Set loading
        this.historyLoading = true;
        // Create query
        const query: GQLTagRequestObject = gql`
          query ($id: String!, $partition: String!, $tenantId: String!) {
            GetTenancy (
              input: {
                id: $id,
                partition: $partition
              }
            ) {
              tenantHistory(id: $tenantId) {
                message
                received
                meta {
                  createdOn
                }
              }
            }
          }
        `;
        // Payload
        const payload: API.GetTenancyInput & { tenantId: string } = {
          id: this.tenancyId,
          partition: this.tenancyPartition,
          tenantId: this.targetTenant.id!,
        };
        // Send request
        try {
          const response: any = await this.$gql.Query('GetTenancy', query, payload);
          if (response) {
            this.history = response.tenantHistory;
            // Scroll to bottom
            this.$nextTick(() => {
              const historyDOMElement: Element = (this.$refs.history as Element);
              historyDOMElement.scrollTop = historyDOMElement.scrollHeight;
            });
          }
        } catch (e) {
          console.error(e);
        } finally {
          this.historyLoading = false;
        }
      } else {
        console.warn('Target Tenant not initialised', this.targetTenant);
      }
    },
    showSendTextView () {
      this.$emit('show', 'SMSSendText');
    },
  },
});
