var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view tenant-view"},[_c('div',{ref:"tenantList",staticClass:"left-view"},[_c('div',{staticClass:"tenant-container"},_vm._l((_vm.tenancy.tenants),function(tenant,idx){return _c('tenant-card',{key:tenant.id,attrs:{"tenant":tenant,"idx":idx},on:{"click":_vm.updateTenant}})}),1),(!_vm.tenantLimitReached)?_c('q-btn',{staticClass:"add-tenant-btn",attrs:{"size":"1.1rem","round":"","color":"green","icon":"mdi-plus"},on:{"click":function($event){return _vm.invokeTenantForm()}}}):_vm._e()],1),_c('div',{staticClass:"right-view",class:{'active': _vm.showForm}},[_c('form',{staticClass:"tenant-upsert-form row",class:{'shake': _vm.animateForm, 'animated': _vm.animateForm},on:{"submit":function($event){$event.preventDefault();return _vm.upsertTenant()}}},[(_vm.showFormOverlay)?_c('div',{staticClass:"overlay"},[_c('h6',[_vm._v("Select a Tenant")]),_c('q-icon',{attrs:{"name":"mdi-arrow-left","size":"2rem","color":"grey"}})],1):_vm._e(),_c('h6',{staticClass:"form-title"},[_vm._v(_vm._s(_vm.formTitle))]),_c('close-btn',{staticClass:"close",on:{"close":function($event){_vm.showForm = false}}}),_c('div',{staticClass:"col-12 input-container"},[_c('q-input',{ref:"firstname",staticClass:"custom",attrs:{"color":"primary","label":"Firstname","autocomplete":"whatjhdgkshdgjshg","lazy-rules":"","filled":"","clearable":"","rules":[
            function (val) { return !!val || '* Required'; },
            function (val) { return val.length < 100 || 'Please use 100 characters at maximum'; }
          ]},on:{"input":function($event){return _vm.debounceUpsert()}},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.firstname"}})],1),_c('div',{staticClass:"col-12 input-container"},[_c('q-input',{ref:"surname",staticClass:"custom",attrs:{"color":"primary","label":"Surname","autocomplete":"whatjhdgkshdgjshg","lazy-rules":"","filled":"","clearable":"","rules":[
            function (val) { return !!val || '* Required'; },
            function (val) { return val.length < 100 || 'Please use 100 characters at maximum'; }
          ]},on:{"input":function($event){return _vm.debounceUpsert()}},model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.surname"}})],1),_c('div',{staticClass:"col-12 input-container"},[_c('q-input',{ref:"mobile",staticClass:"custom",attrs:{"color":"primary","label":"Mobile","autocomplete":"whatjhdgkshdgjshg","lazy-rules":"","filled":"","clearable":"","prefix":"+447","mask":"#########","hint":"We currently only support UK numbers (+447)","rules":[
            function (val) { return !!val || '* Required'; },
            function (val) { return val.length < 100 || 'Please use 100 characters at maximum'; }
          ]},on:{"input":function($event){return _vm.debounceUpsert()}},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.mobile"}})],1),_c('div',{staticClass:"col-12 input-container"},[_c('q-input',{ref:"email",staticClass:"custom",attrs:{"color":"primary","label":"Email","autocomplete":"whatjhdgkshdgjshg","lazy-rules":"","filled":"","clearable":"","rules":[
            function (val) { return !!val || '* Required'; },
            function (val) { return val.length < 100 || 'Please use 100 characters at maximum'; }
          ]},on:{"input":function($event){return _vm.debounceUpsert()}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}})],1),_c('div',{staticClass:"col-12 input-container center"},[(_vm.form.id === '')?_c('q-btn',{staticClass:"custom",attrs:{"type":"submit","size":"1.1rem","no-caps":"","round":"","loading":_vm.updateTenantLoading,"disable":_vm.disableSubmitButton,"color":_vm.form.id === '' ? 'green' : 'red',"icon":_vm.form.id === '' ? 'mdi-plus' : (_vm.confirmDeleteTenant  ? 'mdi-exclamation' : 'mdi-trash-can')}}):_vm._e(),(_vm.form.id !== '')?_c('q-btn',{staticClass:"custom",attrs:{"type":"button","size":"1.1rem","no-caps":"","round":"","loading":_vm.updateTenantLoading,"disable":_vm.disableSubmitButton,"color":"red","icon":(_vm.confirmDeleteTenant  ? 'mdi-exclamation' : 'mdi-trash-can')},on:{"click":function($event){return _vm.obsoleteTenant()}}}):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }