















































































































































































import Vue from 'vue';
import AutomationCard from '@/blueprint/components/landlord/tenancy/Automation-Card.vue';
import CloseBtn from '@/blueprint/components/ui/CloseButton.vue';
import TenantSelector from '@/blueprint/components/landlord/tenancy/Tenant-Selector.vue';
import DeleteBtn from '@/blueprint/components/ui/DeleteButton.vue';
import { date } from 'quasar';
import { ValidateFields, ResetForm } from '@/assets/mixins';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { API } from '@lordly/models2/interfaces/gql';
import { QSelectOptions } from '@/interfaces/quasar';
import { Tenant as ITenant, TenancyScheduledMessage } from '@lordly/models2/interfaces/models/Tenancy';
import { Tenancy, SMSScheduleMessage } from '@/blueprint/pages/landlord/Tenancy.vue';
import { VueInputComponent } from '@/interfaces';
import { debounce } from 'quasar';

export default Vue.extend({
  name: 'Automation-View',
  components: {
    'automation-card': AutomationCard,
    'close-btn': CloseBtn,
    'tenant-selector': TenantSelector,
    'delete-btn': DeleteBtn,
  },
  inject: ['tenancy'],
  props: {
    tenancyId: {
      type: String,
      default: '',
      required: true,
    },
    tenancyPartition: {
      type: String,
      default: '',
      required: true,
    },
  },
  data: () => {
    return {
      showRightView: false,
      form: {
        id: '',
        name: '',
        type: 'SMS',
        recipients: [] as string[],
        frequency: 'Monthly',
        message: '',
        dayOrDate: new Date().toISOString(),
      },
      automationTypes: [
        {
          label: 'Scheduled SMS Messages',
          value: 'SMS',
        },
      ] as QSelectOptions[],
      frequencyTypes: [
        {
          label: 'Weekly (Not available)',
          value: 'Weekly',
        },
        {
          label: 'Monthly',
          value: 'Monthly',
        },
        {
          label: 'Yearly',
          value: 'Yearly',
        },
      ] as QSelectOptions[],
      upsertLoading: false,
      exisitingAutomations: [] as SMSScheduleMessage[],
      debounceUpdate: () => { return ; },
      limit: 4,
    };
  },
  computed: {
    formTitle () {
      let title: string = 'New Automated Message';
      // Handle update
      if (this.form.id) {
        title = 'Update Automated Message';
      }
      return title;
    },
    tenancyObject (): Tenancy {
      return (this as any).tenancy;
    },
    tenants (): Array<Partial<ITenant>> {
      return this.tenancyObject.tenants;
    },
    frequencyValid (): boolean {
      if (this.form.frequency === 'Monthly' || this.form.frequency === 'Yearly') {
        return true;
      } else {
        return false;
      }
    },
    frequencyHelperMessage (): string {
      let message: string = 'Send the message below every ';
      switch (this.form.frequency) {
        case 'Monthly':
        case 'Yearly':
          message += `${this.form.frequency === 'Monthly' ? 'month' : 'year'} on the `;
          const selectedDate: Date = new Date(this.form.dayOrDate);
          // Determine format based on frequnecy
          if (this.form.frequency === 'Monthly') {
            message += date.formatDate(selectedDate, 'Do');
          } else {
            message += date.formatDate(selectedDate, 'Do of MMMM');
          }
          break;
        default:
          message += '(unknown frequency)';
          break;
      }
      return message;
    },
    updateMode (): boolean {
      if (this.form.id) {
        return true;
      }
      return false;
    },
    limitReached (): boolean {
      return this.exisitingAutomations.length >= this.limit;
    },
    showOverlay (): boolean {
      return this.limitReached && this.form.id === '';
    },
  },
  created () {
    this.debounceUpdate = debounce(() => {
      this.triggerUpdate();
    }, 1000);
  },
  mounted () {
    // Set default day as today
    this.form.dayOrDate = date.formatDate(Date.now(), 'YYYY/MM/DD');

    // Sync exisiting automations
    this.exisitingAutomations = this.tenancyObject.automation;
    this.resetAutomationForm();
  },
  methods: {
    addNew () {
      // If in update mode, clear form
      this.resetAutomationForm();
      // BugFix: Open after reset as reset closes the form
      this.showRightView = true;
    },
    closeForm () {
      this.showRightView = false;
    },
    updateRecipients (values: ITenant[]) {
      this.form.recipients = values.map((t) => t.id);
      this.debounceUpdate();
    },
    async upsertAutomationTask (obsolete: boolean = false) {
      // Determine if form is valid
      let hasError: boolean = !ValidateFields(this, ['name', 'message']);
      // If valid
      if (!hasError && this.frequencyValid) {
        // Create query
        const query: GQLTagRequestObject = gql`
          mutation ($id: String!, $partition: String!, $schedule: TenancyScheduleMessageInput!) {
            UpsertScheduledMessage (
              input: {
                id: $id,
                partition: $partition,
                schedule: $schedule
              }
            ) {
              id
              recipients
              label
              frequency
              dayOrDate
              message
              nextRunOn
            }
          }
        `;
        // Create query variables
        const payload: API.TenancyUpsertScheduledMessageInput = {
          id: this.tenancyId,
          partition: this.tenancyPartition,
          schedule: {
            id: this.form.id || '',
            name: this.form.name,
            type: this.form.type,
            recipients: this.form.recipients,
            frequency: this.form.frequency as any,
            message: this.form.message,
            dayOrDate: this.form.dayOrDate,
            obsolete,
          },
        };
        // Send query
        try {
          this.upsertLoading = true;
          const response: Partial<TenancyScheduledMessage> = await this.$gql.Mutation('UpsertScheduledMessage', query, payload);

          // Handle insert case; append to exisiting array
          if (!this.form.id) {
            const newSMSScheduleItem: SMSScheduleMessage = response as SMSScheduleMessage;
            newSMSScheduleItem.type = 'SMS';
            // Push to front of array
            this.exisitingAutomations = [newSMSScheduleItem].concat(this.exisitingAutomations);
            this.tenancyObject.automation = this.exisitingAutomations;
            this.resetAutomationForm();
          } else {
            // Handle update case; update object
            const updatedSMSSchduleItem: SMSScheduleMessage = response as SMSScheduleMessage;
            for (const idx in this.exisitingAutomations) {
              if (this.exisitingAutomations[idx]) {
                const automation: SMSScheduleMessage = this.exisitingAutomations[idx];
                if (automation.id === updatedSMSSchduleItem.id) {
                  // If obsolete, remove it from the list
                  if (obsolete) {
                    this.exisitingAutomations.splice(parseInt(idx), 1);
                    this.tenancyObject.automation = this.exisitingAutomations;
                    this.resetAutomationForm();
                  } else {
                    updatedSMSSchduleItem['type'] = 'SMS';
                    // Since exisiting automation is a copy of tenancyObj.automation, it index values should line up
                    this.exisitingAutomations[idx] = updatedSMSSchduleItem;
                    this.tenancyObject.automation[idx] = updatedSMSSchduleItem;
                  }
                }
              }
            }
          }
        } catch (e) {
          console.error(e);
        } finally {
          this.upsertLoading = false;
        }
      }
    },
    updateForm (idx: number) {
      // Show form first to clear the form then sync form
      this.addNew();
      // Extract automation from exisiting automations array
      const targetAutomation: SMSScheduleMessage = this.exisitingAutomations[idx];
      // Sync the form
      this.form = {
        id: String(targetAutomation.id!),
        name: targetAutomation.label!,
        type: targetAutomation.type,
        recipients: targetAutomation.recipients,
        frequency: targetAutomation.frequency,
        message: targetAutomation.message,
        dayOrDate: targetAutomation.dayOrDate,
      };
      // Show right view if in mobile mode
      this.showRightView = true;
    },
    obsoleteScheduledMessage () {
      this.upsertAutomationTask(true);
    },
    triggerUpdate () {
      if (this.form.id) {
        this.upsertAutomationTask();
      }
    },
    resetAutomationForm () {
      // Extract all possible tenant ids
      const tenantIds: string[] = this.tenants.map((t) => t.id!);
      // Reset the form
      this.form = {
        id: '',
        name: '',
        type: 'SMS',
        recipients: tenantIds,
        frequency: 'Monthly',
        message: '',
        dayOrDate: new Date().toISOString(),
      };
      // Reset the validation
      const keys: string[] = Object.keys(this.form);
      ResetForm(this, keys);
      // Scroll to top off form
      const formDOMElement: Element = this.$refs['form'] as Element;
      formDOMElement.scrollTop = 0;
      // Close right view if open
      if (this.showRightView) {
        this.showRightView = false;
      }
    },
  },
});
