





























































import Vue from 'vue';
import Loading from '@/blueprint/components/ui/Loading.vue';
import DocumentCard from '@/blueprint/components/landlord/tenancy/Document-Card.vue';
import CloseBtn from '@/blueprint/components/ui/CloseButton.vue';

import { Blob } from '@lordly/models2/interfaces';
import { BlobTypes } from '@/assets/clients/blobClient';

export default Vue.extend({
  name: 'Document-View',
  components: {
    'loading': Loading,
    'document': DocumentCard,
    'close-btn': CloseBtn,
  },
  props: {
    tenancyId: {
      type: String,
      default: '',
      required: true,
    },
  },
  data: () => {
    return {
      loadingDocuments: false,
      documents: [] as any[],
      documentsLimit: 9,
      documentSizeLimit: 5,
      showForm: false,
    };
  },
  computed: {
    noDocuments (): boolean {
      return this.documents.length === 0;
    },
    documentsLimitReached (): boolean {
      return this.documents.length >= this.documentsLimit;
    },
  },
  created () {
    this.loadTenancyDocuments();
  },
  methods: {
    removeDocument (idx: number) {
      this.documents.splice(idx, 1);
    },
    async loadTenancyDocuments () {
      // Send request
      try {
        // Set loading to true
        this.loadingDocuments = true;
        // Get all blobs for this tenancy
        const response: Blob[] = await this.$blob.Get('tenancy', this.tenancyId);
        // If we have response
        if (response && response.length > 0) {
          this.documents = response.map((blob) => {
            const uploadedBlob: DocumentBlob = blob as unknown as DocumentBlob;
            uploadedBlob['uploaded'] = true;
            return uploadedBlob;
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingDocuments = false;
      }
    },
    submitNewDocuments (e: any) {
      // Close the form if open
      this.showForm = false;
      //
      for (const idx in e.target.files) {
        // BugFix: Idx check in place to prevent duplicate keys
        if ((parseInt(idx) >= 0) && e.target.files[idx] && !this.documentsLimitReached) {
          // Extract file
          const file: File = e.target.files[idx] as File;
          // Add images to form array
          const image: DocumentBlob = {
            id: '',
            url: '',
            uploaded: false,
          };
          // Add image to form
          this.documents.push(image);
          const imageIdx: number = this.documents.length - 1;
          let entity: BlobTypes = 'tenancy';
          // Create the blob
          this.$blob.Create(entity, this.tenancyId, file.name, file, this.documentSizeLimit).then(
            (blobs: Blob[]) => {
              // Update created blob info
              if (blobs && blobs.length > 0) {
                this.documents[imageIdx].id = blobs[0].id;
                this.documents[imageIdx].url = blobs[0].url;
                this.documents[imageIdx].uploaded = true;
              }
            },
          ).catch(
            () => {
              // Remove image
              this.documents.splice(imageIdx, 1);
            },
          );
        } else {
          if ((parseInt(idx) >= 0) && this.documentsLimitReached) {
            this.$q.notify(({
              message: `Document limit of ${this.documentsLimit} has been reached`,
              color: 'red',
              timeout: 5000,
            }));
          }
        }
      }
      // Reset form
      if (this.$refs.documentForm) {
        (this.$refs.documentForm as HTMLFormElement).reset();
      }
    },
  },
});

export type DocumentBlob = Blob & { uploaded: boolean };
