











import Vue from 'vue';
import { date } from 'quasar';

export default Vue.extend({
  name: 'Automation-Card-Component',
  props: {
    automation: {
      type: Object,
      required: true,
      default: () => ({
        id: '',
        label: '',
        type: '',
        nextRunOn: '',
      }),
    },
  },
  data: () => {
    return {};
  },
  computed: {
    type (): string {
      if (this.automation.type === 'SMS') {
        return 'Scheduled SMS Message';
      }
      return '';
    },
    nextRunDate (): string {
      if (this.automation.nextRunOn) {
        return date.formatDate(new Date(this.automation.nextRunOn), 'Do MMMM YYYY');
      }
      return '';
    },
  },
});
