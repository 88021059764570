
























































import Vue from 'vue';
import Loading from '@/blueprint/components/ui/Loading.vue';

// Dyanamic Components
import TODOView from '@/blueprint/views/landlord/tenancy/TODO.vue';
import TenantView from '@/blueprint/views/landlord/tenancy/Tenant.vue';
import SMSView from '@/blueprint/views/landlord/tenancy/SMS.vue';
import AutomationView from '@/blueprint/views/landlord/tenancy/Automation.vue';
import DocumentView from '@/blueprint/views/landlord/tenancy/Document.vue';
import OverviewView from '@/blueprint/views/landlord/tenancy/Overview.vue'; // DevNote: Needs implementing...

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { API } from '@lordly/models2/interfaces/gql';
import { Tenancy as ITenancy, Tenant as ITenant, TenancyScheduledMessage } from '@lordly/models2/interfaces/models/Tenancy';
import { Property } from '@lordly/models2/interfaces/models/Property';
import { ActionPayload } from '@/interfaces';

export default Vue.extend({
  name: 'Tenancy-Page',
  components: {
    loading: Loading,
    TODOView,
    TenantView,
    SMSView,
    AutomationView,
    DocumentView,
    OverviewView,
  },
  provide (): Record<string, any> {
    return {
      tenancy: this.getT,
    };
  },
  data () {
    return {
      tenancyId: '',
      tenancyPartition: '',
      showMobileMenu: false,
      sections: [
        // {
        //   label: 'Overview',
        //   component: 'OverviewView',
        // },
        {
          label: 'TODO',
          component: 'TODOView',
        },
        {
          label: 'Tenants',
          component: 'TenantView',
        },
        {
          label: 'Messages',
          component: 'SMSView',
        },
        {
          label: 'Automation',
          component: 'AutomationView',
        },
        {
          label: 'Documents',
          component: 'DocumentView',
        },
      ] as Section[],
      currentSection: '',
      tenancyLoading: false,
      tenancy: {
        tenantLimit: 0,
        todos: [],
        tenants: [],
        automation: [],
      } as Tenancy,
      confirmDelete: false,
    };
  },
  computed: {
    getT (): Tenancy {
      return this.tenancy;
    },
  },
  created () {
    // By default load section 1
    this.currentSection = this.sections[0].component;
    // Set params
    this.tenancyId = this.$route.params.id;
    this.tenancyPartition = this.$route.params.partition;
    // Get tenancy
    this.getTenancy();
  },
  methods: {
    toggleMenu () {
      this.showMobileMenu = !this.showMobileMenu;
    },
    showSection (section: Section) {
      this.currentSection = section.component;
      if (this.showMobileMenu) {
        this.showMobileMenu = false;
      }
    },
    async getTenancy () {
      // Set loading
      this.tenancyLoading = true;
      // Create query
      const query: GQLTagRequestObject = gql`
        query ($id: String!, $partition: String!) {
          GetTenancy (
            input: {
              id: $id,
              partition: $partition
            }
          ) {
            property {
              details {
                bedroom
              }
            }
            todos {
              list {
                label
                value
              }
            }
            tenants {
              id
              color
              firstname
              surname
              contact {
                mobile
                email
              }
              meta {
                obsolete
              }
            }
            scheduledMessages {
              id
              recipients
              label
              frequency
              dayOrDate
              message
              nextRunOn
            }
          }
        }
      `;
      // Create payload
      const payload: API.GetTenancyInput = {
        id: this.tenancyId,
        partition: this.tenancyPartition,
      };
      // Perform query
      try {
        const response: Partial<ITenancy> = await this.$gql.Query('GetTenancy', query, payload);
        if (response) {

          // Assign standard things
          this.tenancy.tenantLimit = (response.property as Partial<Property>).details!.bedroom;
          this.tenancy.todos = response.todos!.list;
          this.tenancy.tenants = response.tenants!;

          // Add type to each scheduled message
          this.tenancy.automation = (response.scheduledMessages as unknown as SMSScheduleMessage[]).map((sms) => {
            sms['type'] = 'SMS';
            return sms;
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.tenancyLoading = false;
      }
    },
    async deleteTenancy () {
      if (this.confirmDelete) {
        // Query
        const query: GQLTagRequestObject = gql`
          mutation ($id: String!, $partition: String!, $obsolete: Boolean!) {
            UpdateTenancy (
              input: {
                id: $id,
                partition: $partition,
                obsolete: $obsolete
              }
            )
            {
              id
            }
          }
        `;
        // Payload
        const payload: API.UpdateTenancyInput = {
          id: this.tenancyId,
          partition: this.tenancyPartition,
          obsolete: true,
        };
        // Send request
        try {
          await this.$gql.Mutation('UpdateTenancy', query, payload);
          // BugFix - Refresh portfolio async so that tenancies count is correct + that we can route back asap
          this.$store.dispatch('landlord/actionGetPortfolio', { component: this } as ActionPayload);
          this.$router.push('/landlord/portfolio');
        } catch (e) {
          console.error(e);
        }
      } else {
        this.confirmDelete = true;
        window.setTimeout(() => {
          this.confirmDelete = false;
        }, 3000);
      }
    },
   },
});

interface Section {
  label: string;
  component: string;
}

export interface TODOItem {
  label: string;
  value: boolean;
}

export type SMSScheduleMessage = TenancyScheduledMessage & { type: string; };
export interface Tenancy {
  todos: TODOItem[];
  tenantLimit: number;
  tenants: Array<Partial<ITenant>>;
  automation: SMSScheduleMessage[];
}
