




































import Vue from 'vue';
import DeleteBtn from '@/blueprint/components/ui/DeleteButton.vue';
import Loading from '@/blueprint/components/ui/Loading.vue';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { DocumentBlob } from '@/blueprint/views/landlord/tenancy/Document.vue';

export default Vue.extend({
  name: 'Document-Card-Component',
  components: {
    'delete-btn': DeleteBtn,
    'loading': Loading,
  },
  props: {
    document: {
      type: Object as () => DocumentBlob,
      default: () => {
        return {
          id: '',
          url: '',
          uploaded: false,
        };
      },
      required: true,
    },
    tenancyId: {
      type: String,
      default: '',
      required: true,
    },
    index: {
      type: Number,
      default: -1,
      required: true,
    },
  },
  data: () => {
    return {
      documentLoading: false,
    };
  },
  computed: {
    documentName (): string {
       if (this.document && this.document.id) {
        const tenancyId: string = this.tenancyId + '/';
        const regex: RegExp = new RegExp(tenancyId);
        return this.document.id.replace(regex, '');
      } else {
        return 'Unknown Document Name - ' + new Date().toISOString();
      }
    },
    documentUploading (): boolean {
      if (this.document && this.document.uploaded) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    async deleteDocument () {
      try {
        // Set loading and delete document
        this.documentLoading = true;
        await this.$blob.Delete('tenancy', this.tenancyId, this.document.id, this.document.url);
        this.$emit('remove', this.index);
      } catch (e) {
        console.error(e);
      } finally {
        this.documentLoading = false;
      }
    },
  },
});
