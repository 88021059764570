




















import Vue from 'vue';
import TenantChip from '@/blueprint/components/landlord/tenancy/Tenant-Chip.vue';

import { Tenant as ITenant } from '@lordly/models2/interfaces/models/Tenancy';

export default Vue.extend({
  name: 'Tenant-Selector-Component',
  components: {
    'tenant-chip': TenantChip,
  },
  props: {
    preSelectedTenants: {
      type: Array as () => string[],
      default: () => ([]),
      required: false,
    },
    tenants: {
      type: Array as () => ITenant[],
      default: () => ([]),
      required: true,
    },
  },
  data: () => {
    return {
      selectedTenants: [] as ITenant[],
      selectedTenantsWatcher: () => { return ; },
    };
  },
  computed: {
    tenantsAmended (): boolean {
      return !(this.tenants.length === this.selectedTenants.length);
    },
  },
  watch: {
    preSelectedTenants: {
      handler () {
        this.filterSelected();
      },
      deep: true,
    },
  },
  created () {
    this.resetTenants();
    // Create watcher on component creation
    this.createSelectedWatcher();
  },
  methods: {
    removeTenant (idx: number) {
      this.selectedTenants.splice(idx, 1);
    },
    resetTenants () {
      this.selectedTenants = [];
      this.tenants.map((tenant) => {
        const tenantCopy: ITenant = Object.assign({}, tenant);
        this.selectedTenants.push(tenantCopy);
      });
    },
    filterSelected () {
      // Destory watcher to prevent endless watch loop which kills the browser
      this.destorySelectedWatcher();
      // Reset and sync
      this.selectedTenants = [];
      this.preSelectedTenants.forEach((id) => {
        this.tenants.forEach((tenant) => {
          if (tenant.id === id) {
            this.selectedTenants.push(tenant);
          }
        });
      });
      // Recreate watcher
      this.createSelectedWatcher();
    },
    createSelectedWatcher () {
      // Setup watcher
      this.selectedTenantsWatcher = this.$watch('selectedTenants', () => {
        // Handler method
        this.$emit('update', this.selectedTenants);
        // Important - Don't add immediate attribute as this would cause endless while loop between vue watchers in current configuration
      }, { deep: true });
    },
    destorySelectedWatcher () {
      this.selectedTenantsWatcher();
    },
  },
});
