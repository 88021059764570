

















































import Vue from 'vue';
import TenantCard from '@/blueprint/components/landlord/tenancy/Tenant-Card.vue';
import CloseBtn from '@/blueprint/components/ui/CloseButton.vue';

// Views
import SMSHistory from '@/blueprint/views/landlord/tenancy/SMS-History.vue';
import SMSSendText from '@/blueprint/views/landlord/tenancy/SMS-SendText.vue';
type SMSPossibleComponents = 'SMSHistory' | 'SMSSendText';

import { Tenancy } from '@/blueprint/pages/landlord/Tenancy.vue';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';

export default Vue.extend({
  name: 'Tenancy-SMS-View',
  components: {
    'tenant-card': TenantCard,
    'close-btn': CloseBtn,
    SMSHistory,
    SMSSendText,
  },
  inject: ['tenancy'],
  props: {
    tenancyId: {
      type: String,
      default: '',
      required: true,
    },
    tenancyPartition: {
      type: String,
      default: '',
      required: true,
    },
  },
  data: () => {
    return {
      showRightView: false,
      rightViewComponent: 'SMSHistory' as SMSPossibleComponents,
      targetTenantIdx: -1,
    };
  },
  methods: {
    showHistory () {
      this.showRightView = true;
      this.setDefaultTargetTenant();
    },
    closeRightView () {
      if (this.showRightView) {
        this.showRightView = false;
      }
    },
    showTenantHistory (idx: number) {
      this.targetTenantIdx = idx;
      this.showHistory();
    },
    setDefaultTargetTenant () {
      const tenancy: Tenancy = (this as any).tenancy as Tenancy;
      if (this.targetTenantIdx === -1 && tenancy && tenancy.tenants && tenancy.tenants.length > 0) {
        this.targetTenantIdx = 0;
      }
    },
    showComponent (component: SMSPossibleComponents) {
      console.log('Set', component);
      this.rightViewComponent = component;
    },
  },
});
